import React from "react"
import Page from "../components/Page"
import Layout from "../components/Layout"
import SiteMeta from "../components/SiteMeta"
import commonStyles from "./pages.module.scss"
import styles from "./software.module.scss"
import classNames from "classnames"
import BackgroundImage from "../components/BackgroundImage"
import { graphql, useStaticQuery } from "gatsby"

const Software: React.FC = () => {
  const years = new Date().getFullYear() - 2003

  const data = useStaticQuery<GatsbyTypes.SoftwareQuery>(graphql`
    query Software {
      image: file(
        relativePath: { eq: "joel-filipe-RFDP7_80v5A-unsplash.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 60, maxWidth: 1600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ogImage: file(relativePath: { eq: "og-image-software.png" }) {
        childImageSharp {
          fixed(quality: 60, width: 1200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SiteMeta
        title="Software engineering"
        description={`I am a software developer with ${years} years of hands-on experience on every aspect of software development from architecture design and implementation to project management.`}
        cardImage={data?.ogImage?.childImageSharp?.fixed}
      />

      <Page className={commonStyles.headerWrapper}>
        <div
          className={classNames(
            commonStyles.container,
            commonStyles.headerContainer
          )}
        >
          <h1>Soft&shy;ware</h1>
          <p className={commonStyles.lead}>
            I’m a full-stack software developer with {years} years of hands-on
            experience in most aspects of software development process.
            Recently my focus has been in mobile development with React and
            React Native and GraphQL, with emphasis on UI/UX and interaction
            design.
          </p>
          <p className={commonStyles.lead}>
            I have managed software development projects throughout their
            lifecycle, and my roles in projects have often overlapped
            significantly with design and sales. As a co-founder and a board
            member I have insight on what goes into running a company and a keen
            sense of profitability and I have experience of starting an office
            and managing small teams of developers as a technical lead.
          </p>
          <p className={commonStyles.lead}>
            Having worked in diverse and international groups both in software
            development and music industry, my interpersonal skills are good and
            I’m comfortable in most social situations. My background in
            education makes mentoring and leading workshops natural to me.
          </p>
          <p className={commonStyles.lead}>
            I value efficiency, quality and client satisfaction, and I’m
            thorough and methodological in my work. I’m a quick learner and
            constantly striving to acquire new skills. In addition, I am
            interested in sound design and have advanced skills in related
            software.
          </p>
        </div>
        <BackgroundImage
          className={commonStyles.headerImage}
          displayId="software-header"
          fluid={data.image?.childImageSharp?.fluid}
          dark
        />
      </Page>
      <Page>
        <div className={commonStyles.container}>
          <h3>Experience</h3>
          <dl className={styles.row}>
            <dt
              className={classNames(
                styles.companyPeriodContainer,
                styles.leftCol
              )}
            >
              <div className={styles.companyPeriod}>
                <div className={styles.company}>Sangre</div>
                <div className={styles.period}>2015&ndash;present</div>
              </div>
            </dt>
            <dd className={classNames(styles.rightCol, styles.description)}>
              Full-stack developer, co-founder
            </dd>
            <dt className={styles.leftCol}>
              <strong>Company size</strong>
            </dt>
            <dd className={styles.rightCol}>
              <p>16-25 persons</p>
            </dd>
            <dt className={styles.leftCol}>
              <strong>Technologies</strong>
            </dt>
            <dd className={styles.rightCol}>
              <p>
                React, React Native, GraphQL, Node, CSS, HTML, PHP, Java,
                Objective-C, Docker, Postgres, Redis, Nginx, Firebase, AWS,
                Linux, GitLab, CI, Agile/Scrum, other related technologies and
                methodologies
              </p>
            </dd>
            <dt className={styles.leftCol}>
              <strong>Responsibilities</strong>
            </dt>
            <dd className={styles.rightCol}>
              <ul className={styles.listUnstyled}>
                <li>
                  Advanced React and React Native front-end development
                  including internationalized front and back office solutions
                  for clients located in multiple time zones
                </li>
                <li>Remote care applications on dedicated devices</li>
                <li>Advanced mobile concepts</li>
                <li> GraphQL backend implementations in Java and Node</li>
                <li>
                  Integrating (and bridging to React Native) of native SDKs
                  including&mdash;but not limited to&mdash;Bluetooth beacon
                  positioning, video conferencing, Azure AD authentication and
                  image processing in Android Java, Objective-C and Swift
                </li>
                <li>
                  Designing and implementing micro service architectures in Node
                  and Java
                </li>
                <li>
                  Application building and deployment through public facing
                  stores and business distribution
                </li>
                <li>Social media and analytics integrations</li>
                <li>
                  Starting up an office in Warsaw, recruitment, interviewing,
                  performing technical skill assessments and managing developers
                  in cooperation with HR
                </li>
                <li>Developer tooling to facilitate easy onboarding</li>
                <li>Mentoring and code reviews</li>
                <li>Chairman of the board 2018-2020</li>
              </ul>
            </dd>
          </dl>
          <dl className={styles.row}>
            <dt
              className={classNames(
                styles.companyPeriodContainer,
                styles.leftCol
              )}
            >
              <div className={styles.companyPeriod}>
                <div className={styles.company}>Twinkle</div>
                <div className={styles.period}>2005&ndash;2015</div>
              </div>
            </dt>
            <dd className={classNames(styles.rightCol, styles.description)}>
              Full-stack developer, partner
            </dd>

            <dt className={styles.leftCol}>
              <strong>Company size</strong>
            </dt>
            <dd className={styles.rightCol}>
              <p>3-4 persons</p>
            </dd>
            <dt className={styles.leftCol}>
              <strong>Technologies</strong>
            </dt>
            <dd className={styles.rightCol}>
              <p>
                PHP, CSS, HTML, MySQL, React, Cordova, Node, Apache, Linux,
                Redis, other related technologies
              </p>
            </dd>
            <dt className={styles.leftCol}>
              <strong>Responsibilities</strong>
            </dt>
            <dd className={styles.rightCol}>
              <ul className={styles.listUnstyled}>
                <li>
                  I personally managed the client account of a Finnish service
                  station chain, which grew from a local operation to a
                  multi-billion, multi-national chain during the 9 years that
                  the client relationship lasted for. My responsibilities
                  included&hellip;
                  <ul>
                    <li>
                      Implementing their client facing web functions, including
                      but not limited to, online commerce, card application
                      integrations, loyalty card functionalities and a service
                      station search and routing
                    </li>
                    <li>
                      Integrations to internal and external systems including
                      physical loyalty card production and usage data,
                      Salesforce and wind power generation data, among others
                    </li>
                    <li>
                      Representing the client as a technical advisor in meetings
                      and relations with their other partners, including IBM,
                      local service providers, and design, PR and advertisement
                      agencies
                    </li>
                    <li>
                      Custom Extranet solution for lead acquisition, order
                      management, station manager functionalities and others
                    </li>
                    <li>
                      Data visualizations, content management, social media
                      integrations, analytics
                    </li>
                  </ul>
                </li>
                <li>
                  Either implemented or participated in implementing multitude
                  of web services, including&hellip;
                  <ul>
                    <li>
                      Services with high national media attention, making
                      performance, availability and security requirements
                      critical
                    </li>
                    <li>
                      Services with strict accessibility requirements either due
                      to legal concerns or specific client needs
                    </li>
                    <li>
                      Services with real-time functionalities, for example
                      election and/or competition result presentation and
                      integrated live stream video annotations
                    </li>
                  </ul>
                </li>
                <li> Partner since 2006, board member since 2008</li>
              </ul>
            </dd>
          </dl>
          <dl className={styles.row}>
            <dt
              className={classNames(
                styles.companyPeriodContainer,
                styles.leftCol
              )}
            >
              <div className={styles.companyPeriod}>
                <div className={styles.company}>Samstock</div>
                <div className={styles.period}>2003&ndash;2005</div>
              </div>
            </dt>
            <dd className={classNames(styles.rightCol, styles.description)}>
              Software developer
            </dd>
            <dt className={styles.leftCol}>
              <strong>Company size</strong>
            </dt>
            <dd className={styles.rightCol}>
              <p>100+ persons</p>
            </dd>
            <dt className={styles.leftCol}>
              <strong>Technologies</strong>
            </dt>
            <dd className={styles.rightCol}>
              <p>Visual Basic</p>
            </dd>
            <dt className={styles.leftCol}>
              <strong>Responsibilities</strong>
            </dt>
            <dd className={styles.rightCol}>
              <ul className={styles.listUnstyled}>
                <li>
                  Implementing and maintaining a graphical interface for asset
                  management back office software
                </li>
              </ul>
            </dd>
          </dl>
        </div>
      </Page>
      <Page className={commonStyles.center}>
        <div className={commonStyles.container}>
          <h3>Other experience</h3>
          <p>
            Implemented social media channels and web presence for the band{" "}
            <i>Swallow the Sun</i> between 2003 and 2016. Graphical design and
            layout work on multiple album covers. CEO of the band’s company{" "}
            <i>Gloomy Light Productions Ltd</i> from 2008 to 2020.
          </p>
          <h3>Education</h3>
          <h5 className={styles.educationTitle}> Bachelor of Science</h5>
          <p>
            Economics and Business Administration / Computer Science. University
            of Jyväskylä, faculty of Information Technology, 2020.
          </p>
          <h5 className={styles.educationTitle}>Additional studies</h5>
          <p>
            Education. University of Jyväskylä, faculty of Education and
            Psychology, <span className={styles.noWrap}>1998&mdash;2000.</span>
          </p>
          <h3>Language skills</h3>
          <dl className={styles.row}>
            <dt className={styles.leftCol}>
              <strong>Finnish</strong>
            </dt>
            <dd className={styles.rightCol}>native</dd>
            <dt className={styles.leftCol}>
              <strong>English</strong>
            </dt>
            <dd className={styles.rightCol}>excellent</dd>
            <dt className={styles.leftCol}>
              <strong>Polish</strong>
            </dt>
            <dd className={styles.rightCol}>beginner</dd>
          </dl>
        </div>
      </Page>
    </Layout>
  )
}

export default Software
